import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const Aside = () => (
  <Paper sx={{ width: 200, margin: "1em", padding: "1em" }}>
    <Typography variant="h6">Notifications</Typography>

    <Typography variant="body2">
      Notifications appear in the bell Icon in the top right corner of the
      user's screen. They can be used to inform the user of a new message or
      other event.
    </Typography>
    <p />
    <p></p>
    <Typography variant="h7">
      <hr />
      <b>Search</b>
    </Typography>
    <Typography variant="body1">
      Search searches by notification <b>Subject</b> and <b>Body</b> here.
      <br />
    </Typography>
    <p></p>
    <Typography variant="body1">
      <b>Note</b>: You cannot search by any fields other than what is listed
      above.
    </Typography>
  </Paper>
);

export default Aside;
