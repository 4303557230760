import englishMessages from "ra-language-english";

export const messages = {
  simple: {
    action: {
      close: "Close",
      resetViews: "Reset views",
    },
    "create-post": "New post",
  },
  ...englishMessages,
  resources: {
    users: {
      name: "User |||| Users",
      fields: {
        name: "Name",
        role: "Role",
      },
    },
  },
  annotation: {
    tab: {
      summary: "Summary",
    },
    list: {
      search: "Search",
    },
    create: {
      title: "Create Annotation",
    },
    edit: {
      title: "Edit Annotation #%{id}",
    },
    show: {
      title: "Annotation #%{id}: %{message}",
    },
    action: {
      save_and_edit: "Save and Edit",
      save_and_add: "Save and Add",
      save_and_show: "Save and Show",
    },
  },
  audit_entry: {
    tab: {
      summary: "Summary",
    },
    list: {
      title: "Audit Entries",
    },
    show: {
      title: "Audit Entry #%{id} @ %{created_at}",
    },
  },
  config_defaults: {
    field_help: {
      scope: "Scope (One of global, container, organization, or linktoken)",
      description: "Description",
      config_key:
        "Config Key (for example: subsystem.function_name). Should be unique within the scope and lowercase.",
      config_value: "Config Value (JSON)",
    },
    field_labels: {
      scope: "Scope",
      scope_id: "Scope ID",
      config_key: "Config Key",
      description: "Description",
      config_value: "Config Value",
      created_at: "Created At",
      updated_at: "Updated At",
    },
    edit: {
      title: "Edit Config Default #%{id}",
    },
    list: {
      search: "Search",
    },
    show: {
      title: "Config Default #%{id}",
    },
    create: {
      title: "Create Config Default",
    },
  },
  config_overrides: {
    field_help: {
      scope: "Scope (One of global, container, organization, or linktoken)",
      description: "Description",
      config_key:
        "Config Key (for example: subsystem.function_name). Should be unique within the scope and lowercase.",
      config_value: "Config Value (JSON)",
    },
    field_labels: {
      scope: "Scope",
      scope_id: "Scope ID",
      config_key: "Config Key",
      description: "Description",
      config_value: "Config Value",
      created_at: "Created At",
      updated_at: "Updated At",
    },
    edit: {
      title: "Edit Config Override #%{id}",
    },
    list: {
      search: "Search",
    },
    show: {
      title: "Config Override #%{id}",
    },
    create: {
      title: "Create Config Override",
    },
    tab: {
      summary: "Summary",
    },
  },
  user: {
    list: {
      search: "Search",
    },
    form: {
      summary: "Summary",
      personal: "Personal",
      security: "Security",
      sms: "Phone / SMS",
      audits: "Audit Entries",
    },
    edit: {
      title: 'User "%{title}"',
    },
    action: {
      save_and_add: "Save and Add",
      save_and_show: "Save and Show",
    },
  },
  container: {
    response: {
      moved: "Container successfully moved.",
      renamed: "Container successfully renamed.",
    },
    tab: {
      summary: "Summary",
      children: "Child Containers",
      users: "Users",
      nasdaq: "Nasdaq",
      intelligence_trace: "Intelligence Trace",
    },
    list: {
      search: "Search",
    },
    form: {
      naming: "Name",
      summary: "Container Details",
      ecdconfig: "ECD Config",
      nasdaq: "Nasdaq Config",
    },
    edit: {
      title: "Edit Container #%{id}: %{name}",
    },
    show: {
      title: "Container #%{id}: %{name}",
    },
    action: {
      save_and_edit: "Save and Edit",
      save_and_add: "Save and Add",
      save_and_show: "Save and Show",
      save_with_average_note: "Save with Note",
    },
  },
  org: {
    form: {
      information: "Information",
      business_rules: "Business Rules",
      security: "Security and Limits",
    },
    list: {
      search: "Search",
    },
    tab: {
      naming: "Name",
      summary: "Organization Details",
      business: "Business Settings",
      security: "Security Settings",
      linktokens: "Linked Accounts",
      containers: "Containers",
      users: "Users",
    },
    edit: {
      title: "Edit Organization #%{id}: %{name}",
    },
    show: {
      title: "Organization #%{id}: %{name}",
    },
    action: {
      save_and_edit: "Save and Edit",
      save_and_add: "Save and Add",
      save_and_show: "Save and Show",
      save_with_average_note: "Save with Note",
    },
  },
  linktoken: {
    show: {
      title: "Linktoken #%{id}: %{remote_name}",
    },
    edit: {
      title: "Edit Linktoken #%{id}: %{remote_name}",
    },
    form: {
      basics: "Basics",
      ingest: "Ingest",
      summary: "Summary",
      stats: "Statistics",
      adobe_analytics: "Adobe Analytics",
      tte: "TTE",
    },
    chip: {
      activeOnly: "Only Active Accounts",
      failedOnly: "Only Failed Accounts",
      activeOrgOnly: "Only Active Orgs",
    },
  },
  notification: {
    tab: {
      summary: "Summary",
    },
    list: {
      search: "Search",
    },
    create: {
      title: "Create Notification",
    },
    edit: {
      title: "Edit Notification #%{id}",
    },
    show: {
      title: "Notification #%{id}: %{subject}",
    },
    action: {
      save_and_edit: "Save and Edit",
      save_and_add: "Save and Add",
      save_and_show: "Save and Show",
    },
    save_success: "Saved successfully with status: %{status}",
    save_failure: "Save failed: %{message}",
  },
  ecdconfig: {
    list: {
      search: "Search",
    },
    form: {
      naming: "Name",
      summary: "Main Config",
      subsystems: "Subsystem Toggles",
      peakconfig: "Peak Config",
      tsmoothiesigmaconfig: "Tsmoothie Sigma Config",
      cdconfig: "CD Config",
      gaconfig: "GA3/GA4/AA Config",
      advanced: "Advanced",
    },
    create: {
      action: "Create ECD Configuration",
      title:
        "Create ECD Configuration on Container #%{container_id} (%{container_name})",
    },
    edit: {
      action: "Edit ECD Configuration",
      title:
        "Edit ECD Configuration #%{id} on %{organization_name}: Container #%{container_id} (%{container_name})",
    },
    show: {
      action: "Show ECD Configuration",
      title:
        "Show ECD Configuration #%{id} on %{organization_name}: Container #%{container_id} (%{container_name})",
    },
    action: {
      save_and_edit: "Save and Edit",
      save_and_add: "Save and Add",
      save_and_show: "Save and Show",
    },
  },
  auth: {
    nosession: "You are not logged in",
    codetext: "Two-factor authentication code",
    badmfa: "Invalid MFA Code",
    insufficient: "Insufficient Access",
    expired: "Session Expired",
    mfaRequired: "Admin system requires MFA. Please update your account.",
  },
  classification: {
    tab: {
      summary: "Summary",
    },
  },
  configdefaults: {
    tab: {
      summary: "Summary",
    },
    list: {
      search: "Search",
    },
    create: {
      title: "Create Config Default",
    },
    edit: {
      title: "Edit Config Default #%{id}",
    },
    show: {
      title: "Config Default #%{id}",
    },
    action: {
      save_and_edit: "Save and Edit",
      save_and_add: "Save and Add",
      save_and_show: "Save and Show",
    },
  },
  prompt_template: {
    tab: {
      summary: "Summary",
    },
    create: {
      title: "Create Prompt Template",
    },
    edit: {
      title: "Edit Prompt Template #%{id}",
    },
    show: {
      title: "Prompt Template #%{id}",
    },
  },
};

export default messages;
