import React from "react";
import Typography from "@mui/material/Typography";

const Aside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Containers</Typography>
      <Typography variant="body1">
        Containers are the primary unit of account management in Alembic. They
        contain groups of Linked Accounts (linktokens). Containers are also used
        to control correlation between accounts (ECD).
      </Typography>
      <p></p>
      <Typography variant="h7">
        <hr />
        <b>Search</b>
      </Typography>
      <Typography variant="body1">
        Search for a Container by <b>Id</b> or <b>Name</b>.
      </Typography>
      <p></p>
      <Typography variant="body1">
        <b>Note</b>: You cannot search by any fields other than what is listed
        above.
      </Typography>
    </div>
  );
};

export default Aside;
