import React from "react";
import Typography from "@mui/material/Typography";

const Aside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Audit Entries</Typography>
      <Typography variant="body2">
        Discrete events that have occurred in the system.
      </Typography>
      <p></p>
      <Typography variant="h7">
        <hr />
        <b>Search</b>
      </Typography>
      <Typography variant="body1">
        Search the Audit Entries by <b>Action</b> or <b>Model</b>.
      </Typography>
      <p></p>
      <Typography variant="body1">
        <b>Note</b>: You cannot search by any fields other than what is listed
        above.
      </Typography>
    </div>
  );
};

export default Aside;
