import React from "react";
import Typography from "@mui/material/Typography";

const Aside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Configuration Overrides</Typography>
      <Typography variant="body1">
        These overrides set different configurations for a particular scope and
        scope ID.
      </Typography>
      <br />
      <Typography variant="body1">
        Overrides can only be setup after the default settings have been saved.
      </Typography>
      <br />
      <Typography variant="body1">
        New overrides can only be created for entries which have existing
        default settings.
      </Typography>
      <p></p>
      <Typography variant="h7">
        <hr />
        <b>Search</b>
      </Typography>
      <Typography variant="body1">
        Search by <b>Config Key</b>, <b>Reason</b>, or <b>Scope</b>.
      </Typography>
      <p></p>
      <Typography variant="body1">
        <b>Note</b>: You cannot search by any fields other than what is listed
        above.
      </Typography>
    </div>
  );
};

export default Aside;
