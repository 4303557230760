import React from "react";
import Typography from "@mui/material/Typography";

const Aside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Users</Typography>
      <Typography variant="body1">
        Users represent individual users in an organization.
      </Typography>
      <p></p>
      <Typography variant="body1">
        Currently, edit, delete, save, and update features are disabled.
      </Typography>
      <p></p>
      <Typography variant="body1">
        To use Alembic as a existing user, click 'BECOME'. This will log you out
        of the admin interface and Alembic Production (you may only be logged
        into one account at a time.)
      </Typography>
      <p></p>
      <Typography variant="body1">
        When you are finished with the user's account, log out of Alembic.
      </Typography>
      <p></p>
      <Typography variant="h7">
        <hr />
        <b>Search</b>
      </Typography>
      <Typography variant="body1">
        Search for a user by <b>First Name</b>, <b>Last Name</b>, or{" "}
        <b>Email</b>.
      </Typography>
      <p></p>
      <Typography variant="body1">
        <b>Note</b>: You cannot search by any fields other than what is listed
        above.
      </Typography>
    </div>
  );
};

export default Aside;
