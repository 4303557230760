import React from "react";
import Typography from "@mui/material/Typography";

const Aside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Linktokens</Typography>
      <Typography variant="body1">
        Linktokens represent links between external (OAuth2) services and
        Alembic.
      </Typography>
      <p></p>
      <Typography variant="h7">
        <hr />
        <b>Search</b>
      </Typography>
      <Typography variant="body1">
        Search by <b>Remote Name</b> only.
      </Typography>
      <p></p>
      <Typography variant="body1">
        <b>Note</b>: You cannot search by any fields other than what is listed
        above.
      </Typography>
    </div>
  );
};

export default Aside;
