/*
React Admin: Data Provider architecture
A Data Provider must have the following methods:

const dataProvider = {
    getList:    (resource, params) => Promise,
    getOne:     (resource, params) => Promise,
    getMany:    (resource, params) => Promise,
    getManyReference: (resource, params) => Promise,
    create:     (resource, params) => Promise,
    update:     (resource, params) => Promise,
    updateMany: (resource, params) => Promise,
    delete:     (resource, params) => Promise,
    deleteMany: (resource, params) => Promise,
}

The Data Provider is the interface between the React Admin components and the
API. It is responsible for fetching data from the API, sending data to the API,
and transforming the data to the format expected by React Admin.

*/

// in src/dataProvider.js
import { buildQuery } from "ra-data-graphql-simple";

import { AUDIT_ENTRY_ONE, AUDIT_ENTRY_LIST } from "./graphql/audit_entries";

import {
  ANNOTATION_CREATE,
  ANNOTATION_ONE,
  ANNOTATION_LIST,
  ANNOTATION_DELETE,
  ANNOTATION_UPDATE,
} from "./graphql/annotations";

import {
  CONFIGDEFAULT_CREATE,
  CONFIGDEFAULT_UPDATE,
  CONFIGDEFAULT_ONE,
  CONFIGDEFAULT_LIST,
  CONFIGDEFAULT_DELETE,
} from "./graphql/configdefault";

import {
  CONFIG_OVERRIDE_CREATE,
  CONFIG_OVERRIDE_ONE,
  CONFIG_OVERRIDE_LIST,
  CONFIG_OVERRIDE_DELETE,
} from "./graphql/configoverride";

import {
  CONTAINER_ONE,
  CONTAINER_LIST,
  MOVE_CONTAINER,
  UPDATE_NASDAQ_TICKERS,
  RENAME_CONTAINER,
} from "./graphql/container";

import { ECDCONFIG_ONE } from "./graphql/ecdconfig";

import {
  NOTIFICATION_CREATE,
  NOTIFICATION_ONE,
  NOTIFICATION_LIST,
  NOTIFICATION_DELETE,
  NOTIFICATION_UPDATE,
} from "./graphql/notifications";

import { ORG_ONE, ORG_LIST, ORG_UPDATE } from "./graphql/organization";

import {
  LINKTOKEN_ONE,
  LINKTOKEN_LIST,
  EXPORT_LINKTOKEN_LIST,
} from "./graphql/linktoken";

import { USERS_ONE, USERS_LIST } from "./graphql/users";

import { USER_CREATE } from "./graphql/user";

import { BECOME_USER } from "./graphql/admin";

import {
  CLASSIFICATION_EVENT_CREATE,
  CLASSIFICATION_EVENT_ONE,
  CLASSIFICATION_EVENT_LIST,
  CLASSIFICATION_EVENT_UPDATE,
  CLASSIFICATION_EVENT_DELETE,
  SEARCH_EVENT_NAMES,
} from "./graphql/classification_events";

import {
  PROMPT_TEMPLATE_ONE,
  PROMPT_TEMPLATE_LIST,
  PROMPT_TEMPLATE_CREATE,
  PROMPT_TEMPLATE_UPDATE,
} from "./graphql/prompt_templates";

import {
  CAUSAL_CHAINS_SUMMARY_ALL,
  CAUSAL_CHAINS_SUMMARY_ONE,
  UPDATE_ADMIN_CAUSAL_CHAINS_SUMMARY,
} from "./graphql/causal_chains_summary";

/**
 * Converts a value to a number. If the conversion fails, returns the default value.
 *
 * @param {string} value - The value to convert to a number.
 * @param {number} defaultValue - The default value to return if conversion fails.
 * @returns {number} - The converted number or the default value.
 */
const getNumber = (value, defaultValue) => {
  const num = parseInt(value, 10);
  return isNaN(num) ? defaultValue : num;
};

/**
 * Builds a GraphQL query based on the provided introspection data.
 *
 * @param {Object} introspection - The introspection data used to build the query.
 * @returns {Function} - A function that takes fetchType, resource, and params to build the query.
 */
const myBuildQuery = (introspection) => (fetchType, resource, params) => {
  // first off, do we have this available directly in the graphql schema? We can
  // try to automatically build it off of the introspection.
  let builtQuery;

  try {
    builtQuery = buildQuery(introspection)(fetchType, resource, params);
  } catch (e) {
    // we don't have it, so we need to build it ourselves
    builtQuery = null;
  }

  // uncomment the next block to debug data provider requests. Extremely helpful
  // when you have to rewrite or transform queries. If you don't see your
  // queries in introspection, you may not have them in the graphQL schema, or,
  // you may not have the single-object resolver available. (e.g.
  // Linktoken/Organization/etc.) Check the introspection.resources object to
  // see if it got picked up. --jna
  //
  // the single query resolver must exist or buildQuery silently fails

  console.log(
    `req: fetchType: ${fetchType} resource: ${resource} builtQueryFound?: ${builtQuery !== null
    }
           params: ${JSON.stringify(params)}`
  );

  // Please also note that for GET_MANY the API must return the full records
  // requested. There is a subtle bug where if the pageSize is limited to say,
  // 10, and then react admin generates a request for hundreds of rows the cache
  // inside of react-query will invalidate, causing react admin to flush the
  // cache. This often exhibits as a blank column for a small number of records
  // in lists and other tables. -- jna 10/28/22

  // Override GraphQL queries here to make them a bit more sane
  switch (resource) {
    case "Annotation":
      switch (fetchType) {
        case "UPDATE":
          return {
            query: ANNOTATION_UPDATE,
            variables: {
              data: {
                id: params.id,
                user_id: params.user_id,
                organization_id: params.data.organization_id,
                container_id: params.data.container_id,
                start_date: params.data.start_date,
                end_date: params.data.end_date,
                message: params.data.message,
                message_type: params.data.message_type,
              },
            },
            parseResponse: (response) => {
              // we have to convert the response to the format that react-admin
              // accepts or we'll get an error
              return {
                data: {
                  ...response.data.updateAdminAnnotation,
                },
              };
            },
          };
        case "DELETE":
          return {
            query: ANNOTATION_DELETE,
            variables: {
              id: params.id,
            },
          };
        case "CREATE":
          return {
            query: ANNOTATION_CREATE,
            variables: {
              data: params.data,
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.createAdminAnnotation,
                },
              };
            },
          };
        case "GET_LIST":
          return { ...builtQuery, query: ANNOTATION_LIST };
        case "GET_MANY": // calls all but uses ids[] filter to select objects
          return { ...builtQuery, query: ANNOTATION_LIST };
        case "GET_ONE":
          return {
            ...builtQuery,
            query: ANNOTATION_ONE,
          };
        default:
      }
      break;
    case "AuditEntry":
      switch (fetchType) {
        case "GET_LIST":
          return { ...builtQuery, query: AUDIT_ENTRY_LIST };
        case "GET_MANY": // calls all but uses ids[] filter to select objects
          return { ...builtQuery, query: AUDIT_ENTRY_LIST };
        case "GET_ONE":
          return {
            ...builtQuery,
            query: AUDIT_ENTRY_ONE,
          };
        default:
      }
      break;
    case "Become":
      switch (fetchType) {
        case "UPDATE":
          return {
            query: BECOME_USER,
            variables: {
              id: parseInt(params.id, 10),
            },
            parseResponse: (response) => {
              return {
                data: {
                  // fake this out because the API doesn't return an ID.
                  id: response.data.becomeUser.user.id,
                  ...response.data.becomeUser,
                },
              };
            },
          };
        default:
      }
      break;
    case "Configdefaults":
      switch (fetchType) {
        case "DELETE":
          return {
            query: CONFIGDEFAULT_DELETE,
            variables: {
              id: params.id,
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.deleteConfigDefault,
                },
              };
            },
          };
        case "CREATE":
          return {
            query: CONFIGDEFAULT_CREATE,
            variables: {
              data: {
                config_key: params.data.config_key,
                config_value: params.data.config_value,
                scope: params.data.scope,
                description: params.data.description,
              },
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.createConfigDefault,
                },
              };
            },
          };
        case "UPDATE":
          return {
            query: CONFIGDEFAULT_UPDATE,
            variables: {
              data: {
                id: params.id,
                config_key: params.data.config_key,
                config_value: params.data.config_value,
                scope: params.data.scope,
                description: params.data.description,
              },
            },
            parseResponse: (response) => {
              console.log(response.data.updateConfigDefault);
              return {
                data: {
                  ...response.data.updateConfigDefault,
                },
              };
            },
          };
        case "GET_LIST":
        case "GET_MANY": // calls all but uses ids[] filter to select objects
          console.log("configdefault list params", params);
          return {
            query: CONFIGDEFAULT_LIST,
            variables: {
              page: params.pagination.page || 1,
              perPage: params.pagination.perPage || 10,
              sortField: params.sort.field || "created_at",
              sortOrder: params.sort.order || "ASC",
              filter: params.filter,
            },
            parseResponse: (response) => {
              return {
                data: response.data.items,
                total: response.data.total.count,
              };
            },
          };
        case "GET_ONE":
          return {
            query: CONFIGDEFAULT_ONE,
            variables: {
              id: parseInt(params.id, 10),
            },
            parseResponse: (response) => {
              console.log("configdefault one response", response);
              return {
                data: {
                  ...response.data.ConfigDefault,
                },
              };
            },
          };
        default:
      }
      break;
    case "Configoverrides":
      switch (fetchType) {
        case "DELETE":
          return {
            query: CONFIG_OVERRIDE_DELETE,
            variables: {
              id: params.id,
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.deleteConfigOverride,
                },
              };
            },
          };
        case "CREATE":
        case "UPDATE":
          return {
            query: CONFIG_OVERRIDE_CREATE,
            variables: {
              data: {
                scope: params.data.scope,
                scope_id: params.data.scope_id,
                reason: params.data.reason,
                config_key: params.data.config_key,
                config_value: params.data.config_value,
              },
            },
            parseResponse: (response) => {
              console.log(response.data.createConfigOverride);
              return {
                data: {
                  ...response.data.createConfigOverride,
                },
              };
            },
          };
        case "GET_LIST":
        case "GET_MANY": // calls all but uses ids[] filter to select objects
          console.log("configoverride list params", params);
          return {
            query: CONFIG_OVERRIDE_LIST,
            variables: {
              page: params.pagination.page || 1,
              perPage: params.pagination.perPage || 10,
              sortField: params.sort.field || "created_at",
              sortOrder: params.sort.order || "ASC",
              filter: params.filter,
            },
            parseResponse: (response) => {
              return {
                data: response.data.items,
                total: response.data.total.count,
              };
            },
          };
        case "GET_MANY": // calls all but uses ids[] filter to select objects
          console.log("configdefault list params", params);
          return {
            query: CONFIGDEFAULT_LIST,
            variables: {
              page: params.pagination.page || 1,
              perPage: params.pagination.perPage || 10,
              sortField: params.sort.field || "created_at",
              sortOrder: params.sort.order || "ASC",
              filter: params.filter,
            },
            parseResponse: (response) => {
              return {
                data: response.data.items,
                total: response.data.total.count,
              };
            },
          };
        case "GET_ONE":
          return {
            query: CONFIG_OVERRIDE_ONE,
            variables: {
              id: parseInt(params.id, 10),
            },
            parseResponse: (response) => {
              console.log("configoverride one response", response);
              return {
                data: {
                  ...response.data.ConfigOverride,
                },
              };
            },
          };
        default:
      }
    case "Container":
      switch (fetchType) {
        case "GET_MANY_REFERENCE": /* used when filtering containers by org */
        case "GET_MANY":
          return { ...builtQuery, query: CONTAINER_LIST };
        case "GET_LIST":
          return { ...builtQuery, query: CONTAINER_LIST };
        case "GET_ONE":
          return {
            ...builtQuery,
            query: CONTAINER_ONE,
          };
        case "UPDATE":
          // we have to handle the different types of updates here

          // this is a ticker update
          if (params.data.nasdaq_tickers) {
            return {
              query: UPDATE_NASDAQ_TICKERS,
              variables: {
                id: params.id,
                nasdaq_tickers: params.data.nasdaq_tickers,
              },
              parseResponse: (response) => {
                return {
                  data: {
                    ...response.data.updateContainer,
                  },
                };
              },
            };
          }

          // rename container
          if (params.data.name) {
            return {
              query: RENAME_CONTAINER,
              variables: {
                id: params.id,
                name: params.data.name,
              },
              parseResponse: (response) => {
                return {
                  data: {
                    ...response.data.renameContainer,
                  },
                };
              },
            };
          }

          // else fire move container
          return {
            query: MOVE_CONTAINER,
            variables: {
              id: params.id,
              parent_id: params.data.new_parent_id,
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.moveContainer,
                },
              };
            },
          };
      }
      break;
    case "ECDConfig":
      switch (fetchType) {
        case "GET_ONE":
          return { ...builtQuery, query: ECDCONFIG_ONE };
        default:
      }
      break;
    case "Linktoken":
      switch (fetchType) {
        case "GET_LIST":
          if (params.pagination.perPage < 20000) {
            // return regular list of linktokens
            return { ...builtQuery, query: LINKTOKEN_LIST };
          } else {
            // return list of linktokens for export use
            return { ...builtQuery, query: EXPORT_LINKTOKEN_LIST };
          }
        case "GET_MANY": // calls all but uses ids[] filter to select objects
          return { ...builtQuery, query: LINKTOKEN_LIST };
        case "GET_ONE":
          return { ...builtQuery, query: LINKTOKEN_ONE };
        default:
      }
      break;

    case "User":
      switch (fetchType) {
        case "CREATE":
          return {
            query: USER_CREATE,
            variables: {
              first_name: params.data.first_name,
              last_name: params.data.last_name,
              email: params.data.email,
              time_zone: params.data.time_zone,
              title: params.data.title,
              password: params.data.password,
              organization_id: params.data.organization_id,
              home_container_id: params.data.home_container_id,
              role_id: params.data.role_id,
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.createUserOnOrganization,
                },
              };
            },
          };
        case "GET_MANY_REFERENCE":
        case "GET_LIST":
          return { ...builtQuery, query: USERS_LIST };
        case "GET_ONE":
          return { ...builtQuery, query: USERS_ONE };
        default:
      }
      break;
    case "Notification":
      switch (fetchType) {
        case "UPDATE":
          return {
            query: NOTIFICATION_UPDATE,
            variables: {
              data: {
                id: params.id,
                to_user_id: getNumber(params.data.to_user_id, null),
                container_id: getNumber(params.data.container_id, null),
                organization_id: getNumber(params.data.organization_id, null),

                scope: params.data.scope,
                subject: params.data.subject,
                body: params.data.body,

                cta_object_type: params.data.cta_object_type,
                cta_object_id: getNumber(params.data.cta_object_id, null),
                url: params.data.url,
              },
            },
            parseResponse: (response) => {
              // we have to convert the response to the format that react-admin
              // accepts or we'll get an error
              return {
                data: {
                  ...response.data.updateAdminNotification,
                },
              };
            },
          };
        case "DELETE":
          return {
            query: NOTIFICATION_DELETE,
            variables: {
              id: params.id,
            },
          };
        case "CREATE":
          return {
            query: NOTIFICATION_CREATE,
            variables: {
              data: {
                to_user_id: getNumber(params.data.to_user_id, null),
                container_id: getNumber(params.data.container_id, null),
                organization_id: getNumber(params.data.organization_id, null),

                scope: params.data.scope,
                subject: params.data.subject,
                body: params.data.body,

                cta_object_type: params.data.cta_object_type,
                cta_object_id: getNumber(params.data.cta_object_id, null),
                url: params.data.url,
              },
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.createAdminNotification,
                },
              };
            },
          };
        case "GET_LIST":
          return { ...builtQuery, query: NOTIFICATION_LIST };
        case "GET_MANY": // calls all but uses ids[] filter to select objects
          return { ...builtQuery, query: NOTIFICATION_LIST };
        case "GET_ONE":
          return {
            ...builtQuery,
            query: NOTIFICATION_ONE,
          };
        default:
      }
      break;
    case "Organization":
      // the full organization graphQL derived from the introspection query call
      // asks for sub-objects and other related items. here we override the
      // query to only return the id and name of the organization and retain the
      // parsing functions.
      switch (fetchType) {
        case "GET_MANY_REFERENCE":
        case "GET_LIST":
        case "GET_MANY": // calls all but uses ids[] filter to select objects
          return {
            ...builtQuery,
            query: ORG_LIST,
            parseResponse: (response) => {
              console.log("get_list response", response);
              return {
                data: response.data.items,
                total: response.data.total.count,
              };
            },
          };
        case "GET_ONE":
          return {
            ...builtQuery,
            query: ORG_ONE,
            parseResponse: (response) => {
              return response.data;
            },
          };
        case "UPDATE":
          return {
            query: ORG_UPDATE,
            variables: {
              data: {
                id: params.id,
                user_id: params.data.user_id,
                account_manager_user_id: params.data.account_manager_user_id,
                name: params.data.name,
                company_short_name: params.data.company_short_name,
                account_manager_user_id: params.data.account_manager_user_id,
                customer_type: params.data.customer_type,
                account_type: params.data.account_type,
                max_containers: params.data.max_containers,
                max_users: params.data.max_users,
                week_start: params.data.week_start,
                year_start: params.data.year_start,
                mfa_mandatory: params.data.mfa_mandatory,
              },
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.updateOrganization,
                },
              };
            },
          };
        default:
      }
      break;
    case "ClassificationEvent":
      switch (fetchType) {
        case "UPDATE":
          return {
            query: CLASSIFICATION_EVENT_UPDATE,
            variables: {
              data: {
                id: params.id,
                linked_account_id: params.data.linked_account_id,
                goal_id: params.data.goal_id,
                event_name: params.data.event_name,
                event_value: params.data.event_value,
                classification: params.data.classification,
              },
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.updateAdminClassificationEvent,
                },
              };
            },
          };
        case "DELETE":
          return {
            query: CLASSIFICATION_EVENT_DELETE,
            variables: {
              id: params.id,
            },
          };
        case "CREATE":
          return {
            query: CLASSIFICATION_EVENT_CREATE,
            variables: {
              data: {
                linked_account_id: params.data.linked_account_id,
                goal_id: params.data.goal_id,
                event_name: params.data.event_name,
                event_value: params.data.event_value,
                classification: params.data.classification,
              },
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.createAdminClassificationEvent,
                },
              };
            },
          };
        case "GET_LIST":
          return { ...builtQuery, query: CLASSIFICATION_EVENT_LIST };
        case "GET_MANY":
          const { action, variables } = params;

          // custom resource method for searching event names
          if (action == "SEARCH_EVENT_NAMES") {
            return {
              ...builtQuery,
              query: SEARCH_EVENT_NAMES,
              variables,
            };
          }

          return { ...builtQuery, query: CLASSIFICATION_EVENT_LIST };
        case "GET_ONE":
          return {
            ...builtQuery,
            query: CLASSIFICATION_EVENT_ONE,
          };
        default:
      }
      break;
    case "PromptTemplate":
      switch (fetchType) {
        case "CREATE":
          return {
            query: PROMPT_TEMPLATE_CREATE,
            variables: {
              data: params.data,
            },
            parseResponse: (response) => {
              // we have to convert the response to the format that react-admin
              // accepts or we'll get an error
              return {
                data: {
                  ...response.data.createAdminPromptTemplate,
                },
              };
            },
          };
        case "UPDATE":
          return {
            query: PROMPT_TEMPLATE_UPDATE,
            variables: {
              data: {
                id: params.id,
                container_id: params.data.container_id,
                stages: params.data.stages,
                is_default: params.data.is_default,
              },
            },
            parseResponse: (response) => {
              // we have to convert the response to the format that react-admin
              // accepts or we'll get an error
              return {
                data: {
                  ...response.data.updateAdminPromptTemplate,
                },
              };
            },
          };
        case "GET_LIST":
        case "GET_MANY": // calls all but uses ids[] filter to select objects
          return { ...builtQuery, query: PROMPT_TEMPLATE_LIST };
        case "GET_ONE":
          return { ...builtQuery, query: PROMPT_TEMPLATE_ONE };
        default:
      }
    case "CausalChainsSummaryAll":
      switch (fetchType) {
        case "GET_LIST":
        case "GET_MANY":
          return {
            query: CAUSAL_CHAINS_SUMMARY_ALL,
            variables: {
              page: params.pagination.page - 1 || 0,
              perPage: params.pagination.perPage || 10,
              sortField: params.sort.field || "created_at",
              sortOrder: params.sort.order || "ASC",
              filter: params.filter,
            },
            parseResponse: (response) => {
              if (!response.data || !response.data.items) {
                console.error("Unexpected response structure:", response);
                throw new Error(
                  "Invalid response structure from causalChainsSummaryAll query"
                );
              }
              const causalChainsSummaryAll = response.data.items;

              if (!Array.isArray(causalChainsSummaryAll)) {
                console.error(
                  "causalChainsSummaryAll is not an array:",
                  causalChainsSummaryAll
                );
                throw new Error("causalChainsSummaryAll is not an array");
              }

              const total = response?.data?.total?.count;

              return {
                data: causalChainsSummaryAll,
                total: total,
              };
            },
          };
          break;
        case "GET_ONE":
          return {
            query: CAUSAL_CHAINS_SUMMARY_ONE,
            variables: {
              id: params.id,
            },
            parseResponse: (response) => {
              return {
                data: response.data.CausalChainsSummaryOne,
              };
            },
          };
          break;
        case "UPDATE":
          return {
            query: UPDATE_ADMIN_CAUSAL_CHAINS_SUMMARY,
            variables: {
              data: {
                id: params.id,
                status: params.data.status,
              },
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.updateAdminCausalChainsSummary,
                },
              };
            },
          };
        default:
      }
      break;
    default:
  }

  if (!builtQuery) {
    throw new Error(
      `Unknown resource ${JSON.stringify(
        resource
      )}, fetchtype ${fetchType} - nothing in derived schema or overrides.`
    );
  }

  return builtQuery;
};

export default myBuildQuery;
